



























































import {Component, Ref, Vue, Watch, Prop, Emit} from 'vue-property-decorator';
import {CustomerAddress} from '@/models/customer/CustomerAddress';

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import CustomerAddressService from '@/views/customer/address/CustomerAddressService';
import {ActionResult} from '@/models/ActionResult';

// import _ from 'lodash';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _cloneDeep from 'lodash/cloneDeep';
import _each from 'lodash/each';

@Component({
    components: {
        VuePerfectScrollbar,
        CustomerAddressForm: () => import('@/views/customer/address/CustomerAddressForm.vue'),
    }
})
export default class OrderCustomerAddress extends Vue {
    private customerAddressService: CustomerAddressService;

    constructor(){
        super();

        this.customerAddressService = new CustomerAddressService();
    }

    @Ref('customerAddressForm') customerAddressForm!: any;
    @Prop({required: true}) data!: Array<CustomerAddress>;
    @Prop({required: true}) dataSelected!: CustomerAddress;
    @Prop({required: true}) customerId: string;

    popupCustomer: boolean = false;
    customerAddressSelected: CustomerAddress = new CustomerAddress();
    selectedAddress?: CustomerAddress = undefined;

    get customerAddresses(){
        let items = this.data;

        _each(items, (item) => {
            let addressList = new Array<any>();

            if (item.address) {
                addressList.push(item.address);
            }

            // if (item.wards) {
            //     addressList.push(item.wards);
            // }

            if (item.districtName) {
                addressList.push(item.districtName);
            }

            if (item.provinceName) {
                addressList.push(item.provinceName);
            }

            item.addressDisplay = addressList.join(' - ');
        });

        return items;
    }

    settings: any = {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
    };

    addCustomerAddress() {
        this.customerAddressForm.add();
        this.popupCustomer = false;
    }

    customerAddressEdit(customerAddress: CustomerAddress) {
        this.popupCustomer = false;
        this.customerAddressForm.edit(_cloneDeep(customerAddress));
    }

    openPopup(){
        this.popupCustomer = true;

        this.customerAddressSelected = _find(this.customerAddresses, (item: CustomerAddress) => {
            return item.id === this.dataSelected.id;
        })!;
    }

    onCustomerAddressFormClosed(){
        this.popupCustomer = true;
    }

    handleCustomerAddressSelected(tr: any) {
    }

    onCustomerAddressAdded(customerAddress: CustomerAddress) {
        this.customerAddresses.push(customerAddress);
        this.popupCustomer = true;

        if (customerAddress) {
            this.refreshCustomerAddressData(customerAddress);
        }
    }

    onCustomerAddressUpdated(customerAddress: CustomerAddress) {
        const customerAddressInfo = _find(this.customerAddresses, (item: CustomerAddress) => {
            return customerAddress.id === item.id;
        });

        if (customerAddressInfo) {
            customerAddressInfo.provinceId = customerAddress.provinceId;
            customerAddressInfo.provinceName = customerAddress.provinceName;
            customerAddressInfo.districtId = customerAddress.districtId;
            customerAddressInfo.districtName = customerAddress.districtName;
            // customerAddressInfo.wards = customerAddress.wards;
            customerAddressInfo.customAttributes = customerAddress.customAttributes;
            customerAddressInfo.address = customerAddress.address;
        }

        if (this.customerAddressSelected) {
            this.refreshCustomerAddressData(customerAddress);
        }

        this.popupCustomer = true;
    }

    remove(customerAddress: CustomerAddress) {
        const bankAccountByLanguage: any = this.$t('Customer address');
        this.selectedAddress = customerAddress;
        // this.$vs.dialog({
        //     type: 'confirm',
        //     color: 'danger',
        //     title: this.$t('Confirm delete'),
        //     acceptText: this.$t('Accept'),
        //     cancelText: this.$t('Cancel'),
        //     text: this.$t('ConfirmDeleteMessage', [bankAccountByLanguage.toLowerCase(), `${customerAddress.provinceName}/${customerAddress.districtName}`]),
        //     accept: this.confirmRemove
        // });

        this.confirmRemove();
    }

    confirmRemove() {
        if (this.selectedAddress && this.customerId) {
            this.customerAddressService.delete(this.customerId, this.selectedAddress.id)
                .then((result: ActionResult) => {
                    if (result.code > 0) {
                        const index = _findIndex(this.customerAddresses, this.selectedAddress);
                        if (index != -1) {
                            this.$delete(this.customerAddresses, index);
                        }
                        
                        this.$vs.notify({
                            title: '',
                            text: result.message,
                            color: 'success'
                        });
                        
                        this.onRemoveSuccess(this.selectedAddress);
                    }
                })
        }
    }

    async saveDeliveryAddress(){
        if (this.customerAddressSelected && this.customerId) {
            this.popupCustomer = false;
            await this.refreshCustomerAddressData(this.customerAddressSelected);
        }
    }

    async refreshCustomerAddressData(customerAddress: CustomerAddress){
        let deliveryAddress = _find(this.customerAddresses, (value: CustomerAddress)=>{
            return value.id === customerAddress.id;
        }) as CustomerAddress;

        this.customerAddressSelected = deliveryAddress!;
        this.onSaveSuccess(deliveryAddress);
    }

    @Emit('removeSuccess')
    onRemoveSuccess(customerAddress: CustomerAddress | undefined){ 
        return customerAddress;
    }

    @Emit('saveSuccess')
    onSaveSuccess(customerAddress: CustomerAddress){
        return customerAddress;
    }
}
